<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Короткие сессии у этого пользователя: да/нет 

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {lastPppoeSessionsIn, lastIpoeSessionsIn, shortSessionPercentile} from "issue/page/loaders/loaders.js"

export default {
  name: "UserShortSessLoader",
  
  mixins: [loaderMixin],
  
  props: {
    service: Object,
  },
  
  methods: {
    async loadInfo()
    {
      const srv = this.service
      let list

      if (srv.service.match("^/ipoe"))
      {
        list = await lastIpoeSessionsIn(srv.uid, 21600, srv.service)
      }
      else if (srv.service == "/ppp")
      {
        list = await lastPppoeSessionsIn(srv.uid, 21600)
      }

      const percentile = shortSessionPercentile(list)
      return percentile <= 600
    },
  },
}

</script>