<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Проверяет, есть ли мертвые ОНУ на дереве

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {assess} from "issue/page/loaders/loaders.js"

export default {
  name: "TreeOnuOffLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      const onus = this.dependsOn
      return assess(onus, el => el.deregistered) + "-onus"
    },
  },
}

</script>