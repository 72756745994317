<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// На порту сессии завершились одновременно с сессией абонента: никто/часть/все
// Одновременно = плюс минус 1 минута

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import { oldMacsOnPort, assessTermSessionsInList, uidsFromMacs } from "issue/page/loaders/loaders.js"


export default {
  name: "PortTermSessLoader",
  
  mixins: [loaderMixin],
  
  props: {
    lastSess: Array,
    service: Object,
    portMacs: Array,
    alone: String
  },

  methods: {
    async loadInfo() {
      if (this.lastSess.length == 0) return null

      var oldMacs = await oldMacsOnPort(this.service.device, this.service.port, null)
      oldMacs = oldMacs.map(el => { el.mac = el.mac.replace(/:/g,""); return el })

      var clientMac  = oldMacs.find(el => el.mac == this.service.mac)
      var neighbours = oldMacs.filter(el => !this.portMacs.includes(el.mac) && el.mac != this.service.mac)

      const assessment = assessTermSessionsInList(clientMac.end, neighbours)

      assessment.detailed = await uidsFromMacs(assessment.detailed.map(el => el.mac))
      assessment.assessment += '-sessions'

      return assessment
    },
  },
}

</script>