<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Получает список всех МАКов на порту в данный момент, кроме текущего абонента

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {macsOnPort} from "issue/page/loaders/loaders.js"

export default {
  name: "PortMacLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      return macsOnPort(this.dependsOn.device, this.dependsOn.port, this.dependsOn.mac)
    },
  },
}

</script>