<template>
  <div v-if="fact">
    {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "OnuStatusInd",

  mixins: [IndMixin],

  computed: {
    status () {
      if (!this.fact) return 'Статус ONU неизвестен'

      var status = 'ONU ' + this.fact.name 

      if (this.fact.deregistered) {
        return `${status} отключена (${this.fact.deregistered})`
      } else {
        return `${status} работает, OLT RX: ${this.fact.olt_rx ? this.fact.olt_rx + 'dBm' : 'неизвестно'}, ONU RX: ${ this.fact.onu_rx ? this.fact.onu_rx + 'dBm' : 'неизвестно'}`
      }
    },

    shortStatus () {
      if (this.fact.deregistered) {
        return `отключен (${this.fact.deregistered})`
      } else {
        return 'активен'
      }
    },

    onuName () {
      return this.fact.name
    },

    oltRx () {
      return `OLT RX: ${this.fact.olt_rx ? this.fact.olt_rx + 'dBm' : 'неизвестно'}`
    },

    onuRx () {
      return `ONU RX: ${ this.fact.onu_rx ? this.fact.onu_rx + 'dBm' : 'неизвестно'}`
    }

  }
}
</script>