<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Получает списки МАКов на всех деревьях (исключая дерево пользователя)

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {mapOlt} from "issue/page/loaders/loaders.js"

export default {
  name: "OltMacLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      const oltStatus = this.dependsOn
      return mapOlt(oltStatus, onu => onu.fdb.map(el => el.mac.replace(/:/g,"")))
    },
  },
}

</script>
