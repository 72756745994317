<template>
  <div v-if=isFact>
    <Icon name="fa-hourglass" class="fa-spin" v-show=isLoading />
    {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "UserLossInd",
  mixins: [IndMixin],    
  
  computed: {
    status() {
      if (typeof this.fact == 'undefined') { return 'Осуществляется пинг абонента...' }
      return this.fact ? "У пользователя есть потери" : "Нет потерь у пользователя"
    }
  },
}
</script>
