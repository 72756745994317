<template>
  <div v-if="fact">
    {{status}}
  </div>
</template>

<script>
// Отсутствие всех MAC-адресов на дереве абонента
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "TreeMacStatusInd",
  mixins: [IndMixin],    
  
  computed: {
    status()
    {
      if (this.fact=="macs-present") return "На дереве есть MAKи" 
      if (this.fact=="no-macs-present") return "На дереве нет МАКов"
      return `${this.$options.name}: ?`
    }
  },
}
</script>
