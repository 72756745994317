<template>
  <div v-if="fact && fact.find(el => el=='group')">
  Новость: проблема группы клиентов
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "UserProblemInd",
  mixins: [IndMixin],    
  
  props: {
    service: Object,
  },
  
}
</script>
