var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fact
    ? _c(
        "div",
        [
          _vm._v("\n  Устройство '" + _vm._s(_vm.fact.name) + "'\n  "),
          _vm.fact.down
            ? [
                _vm._v(
                  "\n    не работает с " + _vm._s(_vm.fact.down_from) + "\n  "
                )
              ]
            : [_vm._v("\n    в порядке\n  ")]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }