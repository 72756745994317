var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fact && _vm.fact.find(el => el == "group")
    ? _c("div", [_vm._v("\nНовость: проблема группы клиентов\n")])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }