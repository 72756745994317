<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Читает статус остальных ONU в дереве

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "TreeStatusLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      return await darsan.get("", "device", `/pon/${this.dependsOn.head}/tree/${this.dependsOn.tree}/onu`)
    },
  },
}

</script>