<template>
  <div v-if="fact!=null">
  {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"
import moment from "moment"

export default {
  
  name: "UserShortSessInd",
  mixins: [IndMixin],    
  
  computed: {
    status()
    {
      return this.fact ? "У пользователя были короткие сессии" : "У пользователя не было коротких сессий"
    }
  },
}
</script>
