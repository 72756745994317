<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Другие короткие сессии на порту: все/некоторые/нет

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {assessShortSessionsInList} from "issue/page/loaders/loaders.js"

export default {
  name: "UidsShortSessLoader",
  
  mixins: [loaderMixin],

  props: {
    uids: Array
  },
  
  methods: {
    async loadInfo() {
      return (await assessShortSessionsInList(this.uids))+"-short-sessions"
    },
  },
}

</script>