<template>
  <div v-if="fact">
  {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "SwitchHungSessionInd",
  mixins: [IndMixin],    
  
  computed: {
    status()
    {
      if (this.fact=='all-ports') return "На всех остальных портах есть зависшие сессии"
      if (this.fact=='some-ports') return "На некоторых остальных портах есть зависшие сессии"
      if (this.fact=='no-ports') return "На всех остальных портах нет зависших сессий"
    }
  },
}
</script>
