<template>
  <div v-if=isFact>
    <Icon name="fa-hourglass" class="fa-spin" v-show=isLoading />
    {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "TreeLossInd",
  mixins: [IndMixin],    
  
  computed: {
    status()
    {
      if (this.fact=='all-onus') return "На всех остальных ONU есть потери"
      if (this.fact=='some-onus') return "На некоторых остальных ONU есть потери"
      if (this.fact=='no-onus') return "На всех остальных ONU нет потерь"
      return "Осуществляется пинг всех абонентов на дереве..."
    }
  },
}
</script>
