import darsan from "darsan"
import moment from "moment"

// Берет информацию об устройстве в виде {device: 100, ports: {1: xxx, 2: xxx}} и применяет указанную функцию к каждому порту
// fun: val,key -> any
export function mapSwitch(sw, func)
{
  const newRec = {}
  const keys = Object.keys(sw.ports)
  const results = keys.map(k => func(sw.ports[k], k))
  results.forEach((res, i) => newRec[keys[i]] = res)
  return {device: sw.device, ports: newRec}
}

// Берет информацию об устройстве в виде {device: 100, ports: {1: xxx, 2: xxx}} и применяет указанную функцию к каждому порту
// promise: val,key -> any
export async function asyncMapSwitch(sw, promise)
{
  const newRec = {}
  const keys = Object.keys(sw.ports)
  const results = await Promise.all(keys.map(k => promise(sw.ports[k], k)))
  results.forEach((res, i) => newRec[keys[i]] = res)
  return {device: sw.device, ports: newRec}
}

//////////////////////////////////////////

// Берет информацию о дереве в виде {head: 100, tree: 1, onus: {1: list1, 2: list2}} и применяет указанную функцию к каждому ОНУ
// fun: val,key -> any
// тот же mapSwitch, но на других именах полей
export function mapTree(tr, func)
{
  const newRec = {}
  const keys = Object.keys(tr.onus)
  const results = keys.map(k => func(tr.onus[k], k))
  results.forEach((res, i) => newRec[keys[i]] = res)
  return {head: tr.head, tree: tr.tree, onus: newRec}
}

// Берет информацию об устройстве в виде {head: 100, tree: 1, onus: {1: list1, 2: list2}} и применяет указанную функцию к каждому ОНУ
// promise: val,key -> any
// // тот же asyncMapSwitch, но на других именах полей
export async function asyncMapTree(tr, promise)
{
  const newRec = {}
  const keys = Object.keys(tr.onus)
  const results = await Promise.all(keys.map(k => promise(tr.onus[k], k)))
  results.forEach((res, i) => newRec[keys[i]] = res)
  return {head: tr.head, tree: tr.tree, onus: newRec}
}

//////////////////////////////////////////

// Применение функции к каждому значению объекта
// fun(val, key) -> any
export function mapObject(hash, func)
{
  return Object.fromEntries( Object.entries(hash).map( ([k,v]) => [k, func(v, k)] ))
}

// Берет информацию об OLT в виде {head: 100, trees: { 1: {100: list1, 200: list2}}} и применяет указанную функцию к каждому ОНУ на каждом дереве
// fun: val,key -> any
// тот же mapSwitch, но на вложенных полях trees.1.xxx
export function mapOlt(olt, func)
{
  const newTrees = {}
  for (const tr of Object.keys(olt.trees))
  {
    newTrees[tr] = mapObject(olt.trees[tr], func)
  }
  return {head: olt.head, trees: newTrees}
}

// Берет информацию об устройстве в виде {head: 100, tree: 1, trees: {1: {100: list1, 200: list2}}} и применяет указанную функцию к каждому ОНУ на каждом дереве
// promise: val,key -> any
// // тот же asyncMapSwitch, но на вложенных полях
export async function asyncMapOlt(olt, promise)
{
  const newTrees = {}
  for (const tr of Object.keys(olt.trees))
  {
    const newRec = {}
    const onus = Object.keys(olt.trees[tr])
    const results = await Promise.all(onus.map(k => promise(olt.trees[tr][k], k)))
    results.forEach((res, i) => newRec[onus[i]] = res)
    newTrees[tr] = newRec
  }
  return {head: olt.head, trees: newTrees}
}

////////////////////////////////////

export function assessDetailed (list, cond) {
  if (list.length == 0) return { assessment: 'no',  detailed: [] }
  if (list.every(cond)) return { assessment: 'all', detailed: list }

  var details = list.filter(cond)

  if (details.length == 0) {
    return { assessment: 'no', detailed: [] }
  } else {
    return { assessment: 'some', detailed: details }
  }
}

export function assess (list, cond) {
  if (list.length==0) return "no"
  if (list.every(cond)) return "all"
  return list.some(cond) ? "some" : "no"
}

export function impression (list, cond) {
  return assess(list, el => el != 'no')
}

export function objImpression (obj)
{
  return impression(Object.values(obj))
}

export function treeImpression (tree) {
  return objImpression(tree.onus)
}

export function switchImpression (sw) {
  return objImpression(sw.ports)
}

export function oltImpression (oltAssessment) {
  const treeImp = mapObject(oltAssessment.trees, (v, key) => impression(Object.values(v)))
  return impression(Object.values(treeImp))
}

export function flatAssessment (assess) {
  return assess != "no"
}


export function detailedImpression (list, cond) {
  return assessDetailed(list, cond)
}


export function detailedObjImpression(obj, cond) {
  return detailedImpression(Object.values(obj), cond)
}


export function detailedSwitchImpression(sw, cond) {
  return detailedObjImpression(sw.ports, cond)
}


export function detailedTreeImpression (tree, cond) {
  return detailedObjImpression(tree.onus, cond)
}


export function detailedOltImpression(oltAssessment) {
  const treeImp = mapObject(oltAssessment.trees, (v, key) => detailedImpression(Object.values(v), tree => tree.assessment != 'no'))
  return detailedImpression(Object.values(treeImp), onu => onu.assessment != 'no')
}


//////////////////////////////////////////////

// Берет список Маков и отображает его в список уидов (и, возможно, ip, если у уида есть сессия)
export async function uidsFromMacs(macList)
{
   const list = await darsan.post("", "client", "/clients-from-macs", {macs: macList.join(",")})
   return list.map(el => ({uid: el.uid, ip: el.ip, login: el.login, mac: el.mac.replaceAll(':', '') }))
}

// Список МАКов на порту
export async function macsOnPort(device, port, macToExclude)
{
  const list = await darsan.get("", "device", `/switch/${device}/port/${port}/fdb`)
  return list.map(el => el.mac.replace(/:/g,"")).filter(el => el != macToExclude)
}

// Старые МАКи на порту
export async function oldMacsOnPort(device, port, macToExclude)
{
  const list = await darsan.get("", "device", `/switch/${device}/port/${port}/oldmacs`)
  return list.map(el => { el.mac.replace(/:/g,""); return el }).filter(el => el.mac != macToExclude)
}

// Список портов на устройстве
export async function portsOnSwitch(device, portToExclude)
{
  const dev = await darsan.get("", "device", `/switch/${device}`)
  return Array.from(Array(dev.ports_on.length).keys()).map(el => el+1).filter(el => el != portToExclude)
}

// Список МАКов по всему устройству
export async function macsOnSwitch(device, portToExclude)
{
  const ports = await portsOnSwitch(device, portToExclude)
  const lists = await Promise.all(ports.map(p => macsOnPort(device, p, null)))
  return Object.fromEntries(ports.map((p,i) => [p, lists[p]]))
}

// Где был виден MAC
export async function macSeen (mac) {
  return await darsan.get('', 'device', `/mac/${mac}/seen2`)
}

///////////////////////////////////////////

// Список МАКов на ONU
export async function macsOnOnu(head, port, macToExclude)
{
  const list = await darsan.get("", "device", `/pon/${head}/port/${port}/fdb`)
  return list.map(el => el.mac.replace(/:/g,"")).filter(el => el != macToExclude)
}

/////////////////////////////////////////////////

// Определение активных сессий по списку уидов
export async function assessActiveSessionsInList(uidList) {
  return assess(uidList, el => "ip" in el)
}

///////////////////////////////

// Проверит, что сессия, завершилась с разницей в плюс минус одну минуту
export function isTerminated(mom, session) {
  return Math.abs(moment(session.end).diff(mom, "minutes")) < 1
}

// Определение завершившихся сессий по списку старых МАКов
export function assessTermSessionsInList(time, macList) {
  const mom = moment(time)
  return assessDetailed(macList, el => isTerminated(mom, el))
}

//////////////////////////////////////////////

// Список абонентов на дереве из маков
export async function mapTreeUids(tree) {
  let onus = Object.keys(tree.onus)
  let macs = onus.flatMap(onu => tree.onus[onu])
  let uids = await uidsFromMacs(macs)

  var result = {}
  onus.forEach(onu => {
    result[onu] = tree.onus[onu].flatMap(mac => uids.filter(el => el.mac == mac ))
  })

  return { head: tree.head, tree: tree.tree, onus: result }
}

export async function mapOltUids(olt) {
  let trees  = Object.keys(olt.trees)
  var macs   = []
  var result = {}

  trees.forEach(tree => {
    let onus = Object.keys(olt.trees[tree])
    macs.push(onus.flatMap(onu => olt.trees[tree][onu]))
  })

  let uids = await uidsFromMacs(macs.flat())

  trees.forEach(treeNum => {
    let onus = Object.keys(olt.trees[treeNum])
    var tree = {}

    onus.forEach(onu => {
      tree[onu] = olt.trees[treeNum][onu].flatMap(mac => uids.filter(el => el.mac == mac))
    })

    result[treeNum] = tree
  })

  return { head: olt.head, trees: result }
}

/////////////////////////////////////////////

// Список последних сессий за N секунд
export async function lastPppoeSessionsIn(uid, seconds=21600)
{
  return darsan.get("", "client", `/client/${uid}/pppoe/session?timespan=${seconds}`)
}

// Список последних сессий за N секунд
export async function lastIpoeSessionsIn(uid, seconds=21600, serviceToExclude)
{
  let list = await darsan.get("", "client", `/client/${uid}/ipoe/session?timespan=${seconds}`)
  
  if (serviceToExclude)
  {
    const matched = serviceToExclude.match("^/ipoe/(\\d+)")
    list = list.filter(el => el.ipoe.match(`^/client/${srv.uid}/ipoe/${matched[1]}`))
  }
                                  
  return list
}

///////////////////////////////////////////

// Список последних ошибочных авторизаций PPP за N секунд
export async function lastPppoeAuthFailuresIn(uid, seconds=21600)
{
  return darsan.get("", "client", `/client/${uid}/pppoe/error?timespan=${seconds}`)
}

// Подсчет зависших сессий PPP
export function countHung(sessList)
{
  return sessList.filter(el => el.message.search(/Overlimit/)).length
}

// Определение зависших сессий по списку уидов
export async function assessHungSessionsInList(uidList)
{
  const counts = await Promise.all(uidList.map(async el => 
  {
    const sess = await lastPppoeAuthFailuresIn(el.uid, 21600)
    return countHung(sess)
  }))

  return assess(counts, el => el>0)
}

//////////////////////////////////////////////

// Подсчитать число коротких сессий (т.е. завершившихся с сообщением NAS-Request)
export function countShort(sessList) {
  return sessList.filter(el => el.terminate_cause=="NAS-Request").length
}

export function shortSessionPercentile(sessList, percentile=80) {
  if (!sessList.length) { return false }

  sessList.sort((a, b) => { return a.duration - b.duration })
  var index = Math.ceil(percentile / 100 * sessList.length)
  return sessList[index - 1].duration
}

// Определение коротких сессий по списку уидов
export async function assessShortSessionsInList(uidList)
{
  const percentiles = await Promise.all(uidList.map(async el =>
  {
     const list1 = await lastPppoeSessionsIn(el.uid, 21600)
     const list2 = await lastIpoeSessionsIn(el.uid, 21600)

     return shortSessionPercentile(list1.concat(list2))
  }))

  return assess(percentiles, el => el && el <= 600)
}

/////////////////////////////////////////////

// Есть ли потери (по результатам пинга)
export function isLoss(loss) {
  // Если потерь 100%, считаем что хост не отвечает на ICMP
  if (loss == 100) { return false }

  return loss > 1
}

// Определение зависших сессий по списку уидов
export async function assessLossInList(uidList, server, pingCount=50) {
  const counts = await Promise.all(uidList.filter(el => el.ip).map(async el => {
    return isLoss(await ping(server, el.ip, pingCount), pingCount)
  }))

  return assess(counts, el => el)
}

// Пинг адреса
export async function ping(server, ip, pingCount=50)
{
   return new Promise((resolve, reject) =>
   {
     const ws = new WebSocket(`${server}/ip/${ip}?packet=1400`)
     let sent = 0
     let received = 0
     
     const evalLoss = () => sent ? +((sent-received)*100/sent).toFixed(0) : 100

     ws.onmessage = msg =>
     {
       sent++

       msg = JSON.parse(msg.data);
       switch(msg.reply)
       {
        case "OK":
//          me.print("Ответ от " + me.state.ip + ": число байт=" + me.size + " время=" + (1000*msg.time).toFixed(2) + "мс");
          received++
          break;
//        case "MALFORMED":
//          me.print("Битый пакет");
//          break;
//        case "TIMEOUT":
//          sent++;
//          break;
        case "DEST_UNREACH":
            ws.close()
            resolve(100);
          break;
        }
        
        if (sent>=pingCount) 
        {
          resolve(evalLoss())
          ws.close()
          return
        }

     }
     
     ws.onerror = err => resolve(evalLoss())
  })
}
