<template>
  <div v-if="fact">
  {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "OnuAloneInd",
  mixins: [IndMixin],    
  
  computed: {
    status () {
      if (this.fact=='no-mac') return "Нет МАКа на ONU"
      if (this.fact=='alone') return "Индивидуальный ONU"
      if (this.fact=='not-alone') return "Общий ONU"
    },

    shortStatus () {
      if (this.fact=='no-mac') return "отсутствует MAC на ONU"
      if (this.fact=='alone') return "индивидуальный"
      if (this.fact=='not-alone') return "общий"
    }
  },
}
</script>
