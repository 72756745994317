<template>
  <div v-if="fact">
  {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "OnuActiveSessionInd",
  mixins: [IndMixin],    
  
  computed: {
    status()
    {
      if (this.fact=='all-active-sessions') return "На ONU есть сессии у всех других абонентов"
      if (this.fact=='some-active-sessions') return "На ONU нет сессий у некоторых абонентов"
      if (this.fact=='no-active-sessions') return "На ONU нет сессий у всех абонентов"
    }
  },
}
</script>
