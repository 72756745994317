<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Потери на других ONU: все/некоторые/нет

import darsan from "darsan"
import store from "store"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {assessLossInList, asyncMapTree, treeImpression} from "issue/page/loaders/loaders.js"

export default {
  name: "TreeLossLoader",
  
  mixins: [loaderMixin],

  props: {
    tree: Object,
  },
  
  methods: {
    async loadInfo() {
      console.log(store)
      const assessment = await asyncMapTree(this.tree, uidList => assessLossInList(uidList, this.$store.state.config.ping_server, 50))
      return treeImpression(assessment) + "-onus"
    },
  },
}

</script>