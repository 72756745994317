<template>
  <div v-if=isFact>
    <Icon name="fa-hourglass" class="fa-spin" v-show=isLoading />
    {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "SwitchLossInd",
  mixins: [IndMixin],    
  
  computed: {
    status()
    {
      if (this.fact=='all-ports') return "На всех остальных портах есть потери"
      if (this.fact=='some-ports') return "На некоторых остальных портах есть потери"
      if (this.fact=='no-ports') return "На всех остальных портах нет потерь"
    }
  },
}
</script>
