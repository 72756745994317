<template>
  <div v-if="fact">
  {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "TreeHungSessionInd",
  mixins: [IndMixin],    
  
  computed: {
    status()
    {
      if (this.fact=='all-onus') return "На всех остальных ONU есть зависшие сессии"
      if (this.fact=='some-onus') return "На некоторых остальных ONU есть зависшие сессии"
      if (this.fact=='no-onus') return "На всех остальных ONU нет зависших сессий"
      return `${this.$options.name}: ?`
    }
  },
}
</script>
