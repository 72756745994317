var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fact
    ? _c(
        "div",
        [
          _vm._t("default", function() {
            return [
              _c("span", { staticClass: "text-success" }, [
                _vm._v(_vm._s(_vm.name) + ": " + _vm._s(_vm.fact))
              ])
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }