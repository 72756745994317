<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Последняя сессия абонента на этом сервисе

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "UserLastSessLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      const srv = this.dependsOn

      if (srv.service.match("^/ipoe"))
      {
        const list = await darsan.get("", "client", `/client/${srv.uid}/ipoe/session?last=1`)
        const matched = srv.service.match("^/ipoe/(\\d+)")
        return list.filter(el => el.ipoe.match(`^/client/${srv.uid}/ipoe/${matched[1]}`))
      }
      else if (srv.service == "/ppp")
      {
        const list = await darsan.get("", "client", `/client/${srv.uid}/pppoe/session?last=1`)
        return list.map(el => { el.start = el.started; return el })
      }
      else
      {
        return Promise.resolve([])
      }
    },
  },
}

</script>
