<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Остальные порты на свитче отключены

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {assess} from "issue/page/loaders/loaders.js"

export default {
  name: "SwitchPortOffLoader",
  
  mixins: [loaderMixin],
  
  props: {
    service: Object,
    switch: Object,
  },
  
  methods: {
    async loadInfo() {
      const status = this.switch
      const list = status.ports_state.split("")
      list.splice(this.service.port-1, 1)
      return assess(list, el => el=="1") + "-ports"
    },
  },
}

</script>