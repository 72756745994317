<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Определяет место абонента (устройство, тип устройства, порт)

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "AllServicesLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      return await darsan.get("", "client", `/srv/client/${this.dependsOn}/internet-service`)
    },
  },
}

</script>
