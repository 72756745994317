<template>
  <div class="text-danger" v-if="error">Данные отсутствуют</div>
</template>

<script>

// Читает статус дерева

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "OnuStatusLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      return darsan.get("", "device", `/pontree/${this.dependsOn.device}/port/${this.dependsOn.port}/status`)
    },
  },
}

</script>