<template>
  <div v-if="fact">
    <slot><span class="text-success">{{name}}: {{fact}}</span></slot>
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "Ind",
  
  mixins: [IndMixin],    
}
</script>