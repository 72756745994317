<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Получает списки МАКов на всех деревьях (исключая дерево пользователя)

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "OltStatusLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      const status = this.dependsOn
      const list = await darsan.get("", "device", `/pon/${status.head}/trees`)
      const allTrees = list.map(el => el.tree).filter(el => el != status.tree)
                 
      const treeList = await Promise.all(allTrees.map(el => darsan.get("", "device", `/pon/${status.head}/tree/${el}/onu`)))
/*      
      (3) [Array(34), Array(31), Array(14)]
      0: Array(34)
        0: {device: 2039, fdb: Array(2), mac: "E067B3C15977", model: "101Z", name: "epon0/1:1", …}
        1: {device: 2039, fdb: Array(3), mac: "E067B3C1598B", model: "101Z", name: "epon0/1:2", …}
        2: {device: 2039, fdb: Array(7), mac: "E067B3C14AF7", model: "101Z", name: "epon0/1:3", …}
        3: {device: 2039, fdb: Array(8), mac: "E067B3C154E5", model: "101Z", name: "epon0/1:4", …}
*/
      const trees = treeList.map((t,i) => [allTrees[i], Object.fromEntries(t.map(el=>[el.onu, el]))])

      return {
        head: status.head,
        trees: Object.fromEntries(trees)
      }
    },
  },
}

</script>
