var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fact
    ? _c("div", [
        _vm._v(
          "\n  Выбрана сессия " +
            _vm._s(_vm.fact.service) +
            " " +
            _vm._s(_vm.fact.nas) +
            ": " +
            _vm._s(_vm.fact.start) +
            "\n"
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }