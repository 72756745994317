<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Определяет VLAN сервиса

import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import { macSeen } from "issue/page/loaders/loaders.js"

export default {
  name: "ServiceVlanLoader",
  
  mixins: [loaderMixin],

  props: {
    service: Object,
  },
  
  methods: {
    async loadInfo() {
      const seen = await macSeen(this.service.mac)

      if (seen.current && seen.current.length) {
        return this.getLastVlan(seen.current)
      } else if (seen.last_seen && seen.last_seen.length) {
        return this.getLastVlan(seen.last_seen)
      } else {
        return null
      }
    },

    getLastVlan (list) {
      const filtered = this.service.device_type == 'pontree'
                       ? list.filter((el) => el.mac == this.service.mac && el.port == this.service.port && el.pontree_entity == '/pontree/' + this.service.device)
                       : list.filter((el) => el.mac == this.service.mac && el.port == this.service.port && el.device == this.service.device)

      return filtered.sort((a, b) => b.time - a.time)[0].vlan
    }
  },
}

</script>
