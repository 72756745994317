var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fact && _vm.fact.length
    ? _c("div", [
        _vm._v(
          "\nПоследняя сессия: завершена в " +
            _vm._s(this.fact[0].end) +
            " (" +
            _vm._s(this.fact[0].terminate_cause) +
            ")\n"
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }