<template>
 <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Определяет технологию подключения пользователя

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "UserTechnologyLoaderLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo() {
      const res = await darsan.get("", "client", `/client/${this.dependsOn}/tag/Technology`)
      return res.Technology || "Медь"
    },
  },
}

</script>
