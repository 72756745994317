<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Получает список портов на устройстве за исключением того, на котором сервис

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "SwitchPortsLoader",
  
  mixins: [loaderMixin],
  
  props: {
    service: Object,
    status: Object,
  },
  
  methods: {
    async loadInfo()
    {
      const portList = Array.from(Array(this.status.ports.length).keys()).map(el => el+1).filter(el => el!=this.service.port)
      return { device: this.service.device, ports: Object.fromEntries(portList.map(p => [p,null])) }
    },
  },
}

</script>
