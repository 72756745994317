<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Получает описание дерева и его головы

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "TreeInfoLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      const service = this.dependsOn
      const info = await darsan.get("", "device", `/pontree/${service.device}`)
      const devInfo = await darsan.get("", "device", `/pon/${info.head}`)

      return {...info, head_name: devInfo.name, oltDown: devInfo.down, oltDownFrom: devInfo.down_from}
    },
  },
}

</script>
