<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Проверяет, живет ли МАК один на порту

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "PortAloneLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      return darsan.get("", "device", `/srv/issue/switch/${this.dependsOn.device}/port/${this.dependsOn.port}/mac/${this.dependsOn.mac}/alone`)
        .then(rec => rec.status)
    },
  },
}

</script>