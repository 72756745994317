<template>
  <div v-if="fact">
  {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "PortShortSessionInd",
  mixins: [IndMixin],    
  
  computed: {
    status()
    {
      if (this.fact=='all-short-sessions') return "Все остальные сессии на порту - короткие"
      if (this.fact=='some-short-sessions') return "Некоторые сессии на порту короткие"
      if (this.fact=='no-short-sessions') return "Нет коротких сессий на порту"
    }
  },
}
</script>
