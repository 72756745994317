<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Проверяет, живет ли МАК один на ONU

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "OnuAloneLoader",
  
  mixins: [loaderMixin],

  props: {
    service: Object
  },
  
  methods: {
    async loadInfo()
    {
      return darsan.get("", "device", `/srv/issue/switch/${this.dependsOn.head}/port/${this.dependsOn.port}/mac/${this.service.mac}/alone`)
        .then(rec => rec.status)
    },
  },
}

</script>