<template>
  <div v-if="fact">
    {{otherStatus}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "TreeOnuOffInd",
  mixins: [IndMixin],    

  computed: {
    otherStatus()
    {
      if (this.fact=='all-onus') return "Все ONU на этом дереве отключены"
      if (this.fact=='some-onus') return "Часть ONU на этом дереве отключена"
      if (this.fact=='no-onus') return "Все ONU на дереве работают"
      return `${this.$options.name}: ?`
    }
  },
}
</script>
