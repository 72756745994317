<template>
  <div v-if="fact && service">
    {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "PortStatusInd",
  mixins: [IndMixin],    
  
  props: {
    service: Object,
  },
  
  computed: {
    isPortEnabled () {
      return this.fact.ports.charAt(this.service.port-1)=="1"
    },

    status () {
      return 'Порт ' + this.service.port + (this.isPortEnabled ? ' активен' : ' неактивен')
    },

    shortStatus () {
      return this.isPortEnabled ? 'активен' : 'неактивен'
    }
  },
}
</script>
