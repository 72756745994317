<template>
  <div v-if=isFact>
    <Icon name="fa-hourglass" class="fa-spin" v-show=isLoading />
    {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "OltHungSessInd",
  mixins: [IndMixin],    
  
  computed: {
    status()
    {
      if (this.fact=='all-trees') return "На всех остальных деревьях есть зависшие сессии"
      if (this.fact=='some-trees') return "На некоторых остальных деревьях есть зависшие сессии"
      if (this.fact=='no-trees') return "На всех остальных деревьях нет зависших сессий"
    }
  },
}
</script>
