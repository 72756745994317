<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// На дереве сессии завершились одновременно с сессией абонента: никто/часть/все
// Одновременно = плюс минус 1 минута

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import { detailedTreeImpression, assessTermSessionsInList, mapTree, uidsFromMacs } from "issue/page/loaders/loaders.js"

export default {
  name: "TreeTermSessLoader",
  
  mixins: [loaderMixin],
  
  props: {
    lastSess: Array,
    onus: Array,
    service: Object
  },
  
  methods: {
    async loadInfo() {
      if (this.lastSess.length == 0) return null

      const onus = this.onus

      // для удобства собираем в один массив все маки
      // которые в данный момент висят на дереве
      var treeFdb = this.onus.map(onu => onu.fdb).map(fdb => fdb.map(el => el.mac)).flat()

      // выкидываем из списка сессий маков (fdb_old) всё что онлайн в текущий момент
      // и выпиливаем все двоеточия из мак адресов
      const tree = {
        onus: Object.fromEntries(
          onus.map(onu => [onu.onu, onu.old_fdb.filter(el => !treeFdb.includes(el.mac)).map(el => {
            return { mac: el.mac.replace(/:/g, ""), end: el.tstamp }
          })])
        )
      }

      if (!tree.onus[this.service.port]) return null;

      var clientMac  = tree.onus[this.service.port].find(el => el.mac == this.service.mac)
      if (!clientMac) return null

      // выкидываем состояние онушки текущего сервиса
      // ибо её состояние мы выше разбираем отдельно
      // а дублирование инфы нежелательно
      delete tree.onus[this.service.port]
     
      const assessment = mapTree(tree, oldMacs => {
        var neighbours = oldMacs.filter(el => el.mac != clientMac.mac)

        return assessTermSessionsInList(clientMac.end, neighbours)
      })

      var impression = detailedTreeImpression(assessment, onu => onu.assessment != 'no')

      impression.detailed = await uidsFromMacs(impression.detailed.map(detail => detail.detailed.map(el => el.mac) ))
      impression.assessment += '-sessions'

      return impression
    },
  },
}

</script>