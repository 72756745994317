<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Зависшие сессии на других деревьях olt: все/некоторые/нет

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {assessHungSessionsInList, asyncMapOlt, oltImpression} from "issue/page/loaders/loaders.js"

export default {
  name: "OltHungSessLoader",
  
  mixins: [loaderMixin],
  
  props: {
    olt: Object,
  },
  
  methods: {
    async loadInfo()
    {
      const olt = this.olt
      const assessment = await asyncMapOlt(olt, assessHungSessionsInList)
      return oltImpression(assessment) + "-trees"
    },
  },
}

</script>
