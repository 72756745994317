<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Потери на порту: все/некоторые/нет

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {assessLossInList} from "issue/page/loaders/loaders.js"

export default {
  name: "UidsLossLoader",
  
  mixins: [loaderMixin],
  
  props: {
    uids: Array,
  },
  
  methods: {
    async loadInfo()
    {
      const uids = this.uids
      return (await assessLossInList(uids, this.$store.state.config.ping_server, 100)) + "-loss"
    },
  },
}

</script>