<template>
  <div v-if=isFact>
    <Icon name="fa-hourglass" class="fa-spin" v-show=isLoading />
    {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "OnuLossInd",
  mixins: [IndMixin],    
  
  computed: {
    status()
    {
      if (this.fact=='all-loss') return "Все сессии на ONU с потерями"
      if (this.fact=='some-loss') return "Некоторые сессии на ONU с потерями"
      if (this.fact=='no-loss') return "Нет потерь на ONU"
      return "Осуществляется пинг всех абонентов на ONU..."
    }
  },
}
</script>
