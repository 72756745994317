<template>
 <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Потери по IP сессии этого абонента: есть/нет

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {assessLossInList, flatAssessment} from "issue/page/loaders/loaders.js"

export default {
  name: "UserLossLoader",
  
  mixins: [loaderMixin],
  
  props: {
    session: Object,
  },
  
  methods: {
    async loadInfo()
    {
      const session = this.session

      return flatAssessment(await assessLossInList([{ip: session.ip}], this.$store.state.config.ping_server, 100))
    },
  },
}

</script>
