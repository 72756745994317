var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fact
    ? _c("div", [
        _vm._v("\n  " + _vm._s(_vm.status) + "  "),
        _vm.details
          ? _c("span", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.details))
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }