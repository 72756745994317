<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Счетчик сессий по каждой ОНУ дерева (исключаем текущий МАК пользователя)
// (Тяжелая операция)

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "TreeSessLoader",
  
  mixins: [loaderMixin],
  
  props: {
    ourService: Object,
    ourOnu: Object,
  },
  
  methods: {
    async loadInfo()
    {
      const sess = await Promise.all(this.dependsOn.map(onu => this.makeReq(onu)))
      return Object.fromEntries( sess.map((el,i) => [this.dependsOn[i].onu, el.length]) )
    },

    makeReq(onu)
    {
      if (!onu.fdb.length) return Promise.resolve([])
    
      let macs = onu.fdb.map(el => el.mac.replace(/:/g, ""))
      if (onu.onu==this.ourOnuPort)
      {
        macs = macs.filter(el => el != this.ourMac)
      }
    
      return darsan.post("", "client", "/clients-from-macs", {macs: macs.join(",")})
    },
  }
}

</script>