<template>
 <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Читает новости на предмет проблем

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "NewsLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      const res = await darsan.get("", "client", `/client/${this.dependsOn}/news?tags=problem`)
      const value = []

      res.forEach( el => 
      {
        el.tags.forEach(tag =>
        {
          if (tag.match(/^client:/)) value.push("client")
          if (tag.match(/^port:/)) value.push("port")
          if (tag.match(/^device:/)) value.push("device")
          if (tag.match(/^group:/)) value.push("group")
        })
      })
 
      return value
    },
  },
}

</script>