var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.error
    ? _c("div", { staticClass: "text-danger" }, [
        _vm._v(_vm._s(_vm.$options.name) + ": " + _vm._s(_vm.error))
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }