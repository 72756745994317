<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Количество МАКов на остальных деревьях этого устройства

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {mapOlt, oltImpression, assess} from "issue/page/loaders/loaders.js"

export default {
  name: "OtherTreeMacCounterLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      const olt = this.dependsOn      
      const assessment = mapOlt(olt, macList => assess(macList, el => el.length>0))
      return oltImpression(assessment) + "-trees"
    },
  },
}

</script>