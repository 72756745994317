<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Зависающие сессии у этого пользователя: да/нет 

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {flatAssessment, assessHungSessionsInList} from "issue/page/loaders/loaders.js"

export default {
  name: "UserHungSessLoader",
  
  mixins: [loaderMixin],
  
  props: {
    service: Object,
  },
  
  methods: {
    async loadInfo()
    {
      const srv = this.service

      if (srv.service == "/ppp")
      {
        return flatAssessment(await assessHungSessionsInList([{ uid: srv.uid }]))
      }
      else
      {
        return "no-hung-sessions"
      }
    },
  },
}

</script>