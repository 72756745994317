<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Читает статус устройств
// Для коммутаторов это состояние этого порта и соседних
// Для ПОНа - ???

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "Statusloader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      let promise
      
      const srv = this.dependsOn

      if (srv.device_type=="switch")
      {
        promise = darsan.get("", "device", `/device/${srv.device}/status`)
            .then(rec => ({ports: rec.ports_on, down: rec.down, type: "switch", ports_state: rec.ports_adm_on }))
      }
      else if (srv.device_type=="pontree")
      {
         promise = Promise.resolve({}) // ???
           .then(rec => ({type: "pontree"}))
      }
      else
      {
         return Promise.resolve({type: "unknown"})
      }
    
      return await promise
    },
  },
}

</script>