<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// На дереве сессии завершились одновременно с сессией абонента: никто/часть/все
// Одновременно = плюс минус 1 минута

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import { detailedOltImpression, assessTermSessionsInList, mapOlt, uidsFromMacs } from "issue/page/loaders/loaders.js"

export default {
  name: "OltTermSessLoader",
  
  mixins: [loaderMixin],
  
  props: {
    lastSess: Array,
    olt: Object
  },
  
  methods: {
    async loadInfo() {
      if (this.lastSess.length==0) return null

      const macOlt = mapOlt(this.olt, el => el.old_fdb.map(el => ({mac: el.mac.replace(/:/g, ""), end: el.tstamp})))
      const assessOlt = mapOlt(macOlt, oldMacs => assessTermSessionsInList(this.lastSess[0].end, oldMacs))
      
      var impression =  detailedOltImpression(assessOlt)

      impression.assessment += '-sessions'
      impression.detailed = await uidsFromMacs(impression.detailed.map(tree => tree.detailed.map(onu => onu.detailed.map(el => el.mac))));

      return impression
    },
  },
}

</script>