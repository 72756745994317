<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// На ОНУ сессии завершились одновременно с сессией абонента: никто/часть/все
// Одновременно = плюс минус 1 минута

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import { oldMacsOnPort, assessTermSessionsInList, uidsFromMacs } from "issue/page/loaders/loaders.js"

export default {
  name: "OnuTermSessLoader",
  
  mixins: [loaderMixin],
  
  props: {
    lastSess: Array,
    onu: Object,
    service: Object,
    onuMacs: Array
  },
  
  methods: {
    async loadInfo () {
      if (this.lastSess.length == 0) return null

      const onus = await darsan.get("", "device", `/pon/${this.onu.head}/tree/${this.onu.tree}/onu`)
      const ourOnu = onus.find(el => el.onu == this.onu.port)

      if (!ourOnu) return null
      
      const oldMacs = ourOnu.old_fdb.map(el => ({mac: el.mac.replace(/:/g, ""), end: el.tstamp}))

      var clientMac  = oldMacs.find(el => el.mac == this.service.mac)
      var neighbours = oldMacs.filter(el => !this.onuMacs.includes(el.mac) && el.mac != this.service.mac)

      var assessment = assessTermSessionsInList(clientMac.end, neighbours)

      assessment.assessment += '-sessions'
      assessment.detailed = await uidsFromMacs(assessment.detailed.map(el => el.mac))

      return assessment
    },
  },
}

</script>