<script>
  export default {
  
    props: {
      dependsOn: {
        type: [Object, Boolean, String, Array, Number],
      },
      uid: Number,
      test: {
        type: [Object, Array, String],
      },
      activeIf: {
        type: Boolean,
        default: true,
      },
    },
    
    data()
    {
      return {
        error: null,
      }
    },
  
    methods: {
      async load()
      {
        try 
          {
            this.$emit("input", undefined)
            const res = this.test || await this.loadInfo()
            this.error = null
            this.$emit("input", res)
            //console.log("loaded", this.$options.name, res)
          }
          catch(err)
          {
            this.error = err.status ? err.status + ": " + err.statusText : err
            console.log(err)
            this.$emit("input", null)
          }
      },
    },
    
    watch: {
      dependsOn: {
        immediate: true,
        handler: async function(val,old)
        {
          // сбрасываем поле, если оно null или поле неактуально
          if (!val || !this.activeIf)
          {
            this.$emit("input", null)
            return
          }

          await this.load()
        }
      },
    },
  }
</script>
