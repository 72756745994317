<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// На других портах есть сессии, завершившиеся в то же время: на некоторых/на всех/ни на одном
// Одновременно = плюс минус 1 минута

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import moment from "moment"
import {detailedSwitchImpression, mapSwitch, asyncMapSwitch, oldMacsOnPort, assessTermSessionsInList, uidsFromMacs} from "issue/page/loaders/loaders.js"

export default {
  name: "SwitchTermSessLoader",
  
  mixins: [loaderMixin],
  
  props: {
    lastSess: Array,
    switch: Object,
  },
  
  methods: {
    async loadInfo() {
      if (this.lastSess.length == 0) return null

      const old = await asyncMapSwitch(this.switch, (val,port) => oldMacsOnPort(this.switch.device, port, null))
      const assessment = mapSwitch(old, macList => assessTermSessionsInList(this.lastSess[0].end, macList))
      var impression =  detailedSwitchImpression(assessment, port => port.assessment != 'no')

      impression.detailed = await uidsFromMacs(impression.detailed.map(detail => detail.detailed.map(el => el.mac) ))
      impression.assessment += '-sessions'

      return impression
    },
  },
}

</script>