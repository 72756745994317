<template>
  <div v-if="fact">
    Устройство '{{fact.name}}'
    <template v-if="fact.down">
      не работает с {{fact.down_from}}
    </template>
    <template v-else>
      в порядке
    </template>
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "SwitchStatusInd",

  mixins: [IndMixin],    
}
</script>