<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Читает статус устройств коммутатора и портов

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "SwitchStatusloader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      return darsan.get("", "device", `/device/${this.dependsOn.device}/status`)
      .then(rec => ({
        ports: rec.ports_on,
        ports_state: rec.ports_adm_on,
        down: rec.down,
        down_from:rec.down_from,
        device: rec.device_id,
        name: rec.device_comment
      }))
    },
  },
}

</script>