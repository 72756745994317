<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Получает списки МАКов на портах (исключая ОНУ пользователя)

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "TreeMacLoader",
  
  mixins: [loaderMixin],
  
  props: {
    onu: Object,
  },
  
  methods: {
    async loadInfo()
    {
      const status = this.dependsOn
      const onus = status.filter(el => el.onu!=this.onu.port).map(el => [el.onu, el.fdb.map(rec => rec.mac.replace(/:/g,""))])
      return {head: this.onu.head, tree: this.onu.tree, onus: Object.fromEntries(onus)}
    },
  },
}

</script>
