var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isFact
    ? _c(
        "div",
        [
          _c("Icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading,
                expression: "isLoading"
              }
            ],
            staticClass: "fa-spin",
            attrs: { name: "fa-hourglass" }
          }),
          _vm._v("\n  " + _vm._s(_vm.status) + "\n")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }