<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Короткие сессии на других ONU: все/некоторые/нет

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {assessShortSessionsInList, asyncMapTree, treeImpression} from "issue/page/loaders/loaders.js"

export default {
  name: "TreeShortSessLoader",
  
  mixins: [loaderMixin],
  
  props: {
    tree: Object,
  },
  
  methods: {
    async loadInfo()
    {
      const tr = this.tree
      const assessment = await asyncMapTree(tr, assessShortSessionsInList)
      return treeImpression(assessment) + "-onus"
    },
  },
}

</script>