<template>
  <div v-if="fact">
    {{status}} <span class='text-danger' v-if="!!fact.detailed.length">{{details}}</span>
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "SwitchTermSessInd",

  mixins: [IndMixin],    

  computed: {
    status() {
      if (this.fact.assessment == 'all-sessions') return "На устройстве MAC-адреса всех других абонентов исчезли одновременно с MAC-адресом этого абонента: "
      if (this.fact.assessment == 'some-sessions') return "На устройстве MAC-адреса некоторых других абонентов исчезли одновременно с MAC-адресом этого абонента: "
      if (this.fact.assessment == 'no-sessions') return "На устройстве MAC-адреса других абонентов не исчезали одновременно с MAC-адресом этого абонента"
      return `${this.$options.name}: ?`
    },

    details () {
      return this.fact.detailed.map(el => el.login).join(', ')
    }
  },

}
</script>