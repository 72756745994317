<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Активные сессии на других ONU: все/некоторые/нет

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {assessActiveSessionsInList, asyncMapTree, treeImpression} from "issue/page/loaders/loaders.js"

export default {
  name: "TreeActiveSessLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      const tr = this.dependsOn
      const assessment = await asyncMapTree(tr, assessActiveSessionsInList)
      return treeImpression(assessment) + "-onus"
    },
  },
}

</script>