<template>
  <div v-if="fact && fact.length">
  Последняя сессия: завершена в {{this.fact[0].end}} ({{this.fact[0].terminate_cause}})
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"
import moment from "moment"

export default {
  
  name: "LastSessionInd",
  mixins: [IndMixin],    
  
}
</script>
