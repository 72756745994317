<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Получает по списку МАКов на порту список всех уидов абонентов (с IP, если в данный момент есть сессия)

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import {uidsFromMacs} from "issue/page/loaders/loaders.js"

export default {
  name: "UidLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      const macList = this.dependsOn
      return uidsFromMacs(macList)
    },
  },
}

</script>
