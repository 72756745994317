<template>
  <div v-if="fact && status">
  {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "DeviceProblemInd",
  mixins: [IndMixin],    
  
  props: {
    service: Object,
  },

  computed: {
    status () {
      if (this.fact.find(el => el == 'device')) return "Новость: проблема на устройстве"
      return null
    }
  }
  
}
</script>
