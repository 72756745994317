var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("AllServicesLoader", {
        attrs: { dependsOn: _vm.uid },
        model: {
          value: _vm.allServicesFact,
          callback: function($$v) {
            _vm.allServicesFact = $$v
          },
          expression: "allServicesFact"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "panel panel-default" }, [
        _c("div", { staticClass: "panel-heading" }, [
          _vm._v("Исходные данные")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "panel-body" },
          [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("Select", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.chosenServiceVisible,
                      expression: "chosenServiceVisible"
                    }
                  ],
                  attrs: {
                    size: 3,
                    options: _vm.allServicesOptions,
                    label: "Выберите сервис"
                  },
                  model: {
                    value: _vm.chosenServiceFact,
                    callback: function($$v) {
                      _vm.chosenServiceFact = $$v
                    },
                    expression: "chosenServiceFact"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("Select", {
                  attrs: {
                    size: 3,
                    options: _vm.yesNoOptions,
                    label: "Через роутер"
                  },
                  model: {
                    value: _vm.isRouterFact,
                    callback: function($$v) {
                      _vm.isRouterFact = $$v
                    },
                    expression: "isRouterFact"
                  }
                }),
                _vm._v(" "),
                _c("Select", {
                  attrs: {
                    size: 3,
                    options: _vm.yesNoOptions,
                    label: "Платный вызов"
                  },
                  model: {
                    value: _vm.paidVisitFact,
                    callback: function($$v) {
                      _vm.paidVisitFact = $$v
                    },
                    expression: "paidVisitFact"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("Select", {
                  attrs: {
                    size: 3,
                    options: _vm.allSessionsOptions,
                    visible: _vm.chosenSessionVisible,
                    label: "Выберите сессию"
                  },
                  model: {
                    value: _vm.chosenSessionFact,
                    callback: function($$v) {
                      _vm.chosenSessionFact = $$v
                    },
                    expression: "chosenSessionFact"
                  }
                }),
                _vm._v(" "),
                _c("Select", {
                  attrs: {
                    size: 3,
                    options: _vm.problemTypes,
                    visible: true,
                    label: "Тип проблемы"
                  },
                  model: {
                    value: _vm.problemDetailFact,
                    callback: function($$v) {
                      _vm.problemDetailFact = $$v
                    },
                    expression: "problemDetailFact"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("Input", {
                  attrs: {
                    size: 3,
                    datalist: _vm.userPhones,
                    visible: true,
                    label: "Контактный номер телефона"
                  },
                  model: {
                    value: _vm.chosenPhoneFact,
                    callback: function($$v) {
                      _vm.chosenPhoneFact = $$v
                    },
                    expression: "chosenPhoneFact"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("AllSessionLoader", {
              attrs: { dependsOn: _vm.serviceFact },
              model: {
                value: _vm.allSessionsFact,
                callback: function($$v) {
                  _vm.allSessionsFact = $$v
                },
                expression: "allSessionsFact"
              }
            }),
            _vm._v(" "),
            _c("NewsLoader", {
              attrs: { dependsOn: _vm.uid },
              model: {
                value: _vm.newsFact,
                callback: function($$v) {
                  _vm.newsFact = $$v
                },
                expression: "newsFact"
              }
            }),
            _vm._v(" "),
            _c("UserTechnologyLoader", {
              attrs: { dependsOn: _vm.uid },
              model: {
                value: _vm.technologyFact,
                callback: function($$v) {
                  _vm.technologyFact = $$v
                },
                expression: "technologyFact"
              }
            }),
            _vm._v(" "),
            _c("UserInfoLoader", {
              attrs: { dependsOn: _vm.uid },
              model: {
                value: _vm.userInfoFact,
                callback: function($$v) {
                  _vm.userInfoFact = $$v
                },
                expression: "userInfoFact"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "panel panel-default" }, [
        _c("div", { staticClass: "panel-heading" }, [_vm._v("Абонент")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "panel-body" },
          [
            _c("UserProblemInd", {
              ref: "userNews",
              attrs: { fact: _vm.newsFact }
            }),
            _vm._v(" "),
            _c("UserGroupProblemInd", { attrs: { fact: _vm.newsFact } }),
            _vm._v(" "),
            _c("ServiceInd", { attrs: { fact: _vm.serviceFact } }),
            _vm._v(" "),
            _c("SessionInd", { attrs: { fact: _vm.sessionFact } }),
            _vm._v(" "),
            _c("PortAloneLoader", {
              attrs: { dependsOn: _vm.serviceFact, "active-if": _vm.isSwitch },
              model: {
                value: _vm.aloneFact,
                callback: function($$v) {
                  _vm.aloneFact = $$v
                },
                expression: "aloneFact"
              }
            }),
            _vm._v(" "),
            _c("PortAloneInd", {
              ref: "portAlone",
              attrs: { fact: _vm.aloneFact, visible: _vm.isSwitch }
            }),
            _vm._v(" "),
            _c("UserLastSessLoader", {
              attrs: { dependsOn: _vm.serviceFact },
              model: {
                value: _vm.lastSessionFact,
                callback: function($$v) {
                  _vm.lastSessionFact = $$v
                },
                expression: "lastSessionFact"
              }
            }),
            _vm._v(" "),
            _c("UserLastSessInd", {
              ref: "userLastSession",
              attrs: { fact: _vm.lastSessionFact }
            }),
            _vm._v(" "),
            _c("UserShortSessLoader", {
              attrs: {
                dependsOn: _vm.serviceFact && _vm.problemFact == "sometimes",
                service: _vm.serviceFact
              },
              model: {
                value: _vm.shortSessFact,
                callback: function($$v) {
                  _vm.shortSessFact = $$v
                },
                expression: "shortSessFact"
              }
            }),
            _vm._v(" "),
            _c("UserShortSessInd", { attrs: { fact: _vm.shortSessFact } }),
            _vm._v(" "),
            _c("UserHungSessLoader", {
              attrs: {
                dependsOn: _vm.serviceFact && _vm.problemFact == "sometimes",
                service: _vm.serviceFact
              },
              model: {
                value: _vm.hungSessFact,
                callback: function($$v) {
                  _vm.hungSessFact = $$v
                },
                expression: "hungSessFact"
              }
            }),
            _vm._v(" "),
            _c("UserHungSessInd", { attrs: { fact: _vm.hungSessFact } }),
            _vm._v(" "),
            _c("UserLossLoader", {
              attrs: {
                dependsOn: _vm.sessionFact && _vm.problemFact == "badly",
                session: _vm.sessionFact
              },
              model: {
                value: _vm.lossFact,
                callback: function($$v) {
                  _vm.lossFact = $$v
                },
                expression: "lossFact"
              }
            }),
            _vm._v(" "),
            _c("UserLossInd", { attrs: { fact: _vm.lossFact } }),
            _vm._v(" "),
            _c("OnuAloneLoader", {
              attrs: {
                dependsOn: _vm.onuStatusFact,
                "active-if": _vm.isPon,
                service: _vm.serviceFact
              },
              model: {
                value: _vm.onuAloneFact,
                callback: function($$v) {
                  _vm.onuAloneFact = $$v
                },
                expression: "onuAloneFact"
              }
            }),
            _vm._v(" "),
            _c("OnuAloneInd", {
              ref: "onuAlone",
              attrs: { fact: _vm.onuAloneFact, visible: _vm.isPon }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "panel panel-default" }, [
        _c("div", { staticClass: "panel-heading" }, [_vm._v("Порт / ONU")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "panel-body" },
          [
            _c("PortProblemInd", {
              ref: "portNews",
              attrs: { fact: _vm.newsFact }
            }),
            _vm._v(" "),
            _c("PortStateInd", {
              ref: "portState",
              attrs: {
                fact: _vm.switchStatusFact,
                service: _vm.serviceFact,
                visible: _vm.serviceFact && _vm.isSwitch
              },
              model: {
                value: _vm.portStateFact,
                callback: function($$v) {
                  _vm.portStateFact = $$v
                },
                expression: "portStateFact"
              }
            }),
            _vm._v(" "),
            _c("PortStatusInd", {
              ref: "portStatus",
              attrs: {
                fact: _vm.switchStatusFact,
                service: _vm.serviceFact,
                visible: _vm.serviceFact && _vm.isSwitch
              },
              model: {
                value: _vm.portActiveFact,
                callback: function($$v) {
                  _vm.portActiveFact = $$v
                },
                expression: "portActiveFact"
              }
            }),
            _vm._v(" "),
            _c("PortMacLoader", {
              attrs: { dependsOn: _vm.serviceFact, "active-if": _vm.isSwitch },
              model: {
                value: _vm.portMacsFact,
                callback: function($$v) {
                  _vm.portMacsFact = $$v
                },
                expression: "portMacsFact"
              }
            }),
            _vm._v(" "),
            _c("UidLoader", {
              attrs: { dependsOn: _vm.portMacsFact, "active-if": _vm.isSwitch },
              model: {
                value: _vm.portUidsFact,
                callback: function($$v) {
                  _vm.portUidsFact = $$v
                },
                expression: "portUidsFact"
              }
            }),
            _vm._v(" "),
            _c("UidsActiveSessLoader", {
              attrs: {
                dependsOn: _vm.portUidsFact && _vm.aloneFact == "not-alone",
                uids: _vm.portUidsFact,
                "active-if": _vm.isSwitch
              },
              model: {
                value: _vm.portActiveSessFact,
                callback: function($$v) {
                  _vm.portActiveSessFact = $$v
                },
                expression: "portActiveSessFact"
              }
            }),
            _vm._v(" "),
            _c("PortActiveSessInd", {
              attrs: { fact: _vm.portActiveSessFact, visible: _vm.isSwitch }
            }),
            _vm._v(" "),
            _c("UidsShortSessLoader", {
              attrs: {
                dependsOn:
                  _vm.portUidsFact &&
                  _vm.aloneFact == "not-alone" &&
                  _vm.problemFact == "sometimes",
                uids: _vm.portUidsFact,
                "active-if": _vm.isSwitch
              },
              model: {
                value: _vm.portShortSessFact,
                callback: function($$v) {
                  _vm.portShortSessFact = $$v
                },
                expression: "portShortSessFact"
              }
            }),
            _vm._v(" "),
            _c("PortShortSessInd", {
              attrs: { fact: _vm.portShortSessFact, visible: _vm.isSwitch }
            }),
            _vm._v(" "),
            _c("UidsHungSessLoader", {
              attrs: {
                dependsOn:
                  _vm.portUidsFact &&
                  _vm.aloneFact == "not-alone" &&
                  _vm.problemFact == "sometimes",
                uids: _vm.portUidsFact,
                "active-if": _vm.isSwitch
              },
              model: {
                value: _vm.portHungSessFact,
                callback: function($$v) {
                  _vm.portHungSessFact = $$v
                },
                expression: "portHungSessFact"
              }
            }),
            _vm._v(" "),
            _c("PortHungSessInd", {
              attrs: { fact: _vm.portHungSessFact, visible: _vm.isSwitch }
            }),
            _vm._v(" "),
            _c("PortTermSessLoader", {
              attrs: {
                lastSess: _vm.lastSessionFact,
                service: _vm.serviceFact,
                alone: _vm.aloneFact,
                portMacs: _vm.portMacsFact,
                dependsOn:
                  _vm.serviceFact &&
                  _vm.lastSessionFact &&
                  _vm.aloneFact == "not-alone" &&
                  _vm.portMacsFact,
                "active-if": _vm.isSwitch
              },
              model: {
                value: _vm.portTermSessFact,
                callback: function($$v) {
                  _vm.portTermSessFact = $$v
                },
                expression: "portTermSessFact"
              }
            }),
            _vm._v(" "),
            _c("PortTermSessInd", {
              ref: "portTermSess",
              attrs: { fact: _vm.portTermSessFact, visible: _vm.isSwitch }
            }),
            _vm._v(" "),
            _c("UidsLossLoader", {
              attrs: {
                dependsOn:
                  _vm.portUidsFact &&
                  _vm.problemFact == "badly" &&
                  _vm.aloneFact == "not-alone",
                uids: _vm.portUidsFact,
                "active-if": _vm.isSwitch
              },
              model: {
                value: _vm.portLossFact,
                callback: function($$v) {
                  _vm.portLossFact = $$v
                },
                expression: "portLossFact"
              }
            }),
            _vm._v(" "),
            _c("PortLossInd", {
              attrs: { fact: _vm.portLossFact, visible: _vm.isSwitch }
            }),
            _vm._v(" "),
            _c("OnuStatusLoader", {
              attrs: { dependsOn: _vm.serviceFact, "active-if": _vm.isPon },
              model: {
                value: _vm.onuStatusFact,
                callback: function($$v) {
                  _vm.onuStatusFact = $$v
                },
                expression: "onuStatusFact"
              }
            }),
            _vm._v(" "),
            _c("OnuStatusInd", {
              ref: "onuStatus",
              attrs: { fact: _vm.onuStatusFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("OnuMacLoader", {
              attrs: { dependsOn: _vm.onuStatusFact, "active-if": _vm.isPon },
              model: {
                value: _vm.onuMacsFact,
                callback: function($$v) {
                  _vm.onuMacsFact = $$v
                },
                expression: "onuMacsFact"
              }
            }),
            _vm._v(" "),
            _c("UidLoader", {
              attrs: { dependsOn: _vm.onuMacsFact, "active-if": _vm.isPon },
              model: {
                value: _vm.onuUidsFact,
                callback: function($$v) {
                  _vm.onuUidsFact = $$v
                },
                expression: "onuUidsFact"
              }
            }),
            _vm._v(" "),
            _c("UidsActiveSessLoader", {
              attrs: {
                dependsOn: _vm.onuUidsFact && _vm.onuAloneFact == "not-alone",
                uids: _vm.onuUidsFact,
                "active-if": _vm.isPon
              },
              model: {
                value: _vm.onuActiveSessFact,
                callback: function($$v) {
                  _vm.onuActiveSessFact = $$v
                },
                expression: "onuActiveSessFact"
              }
            }),
            _vm._v(" "),
            _c("OnuActiveSessInd", {
              attrs: { fact: _vm.onuActiveSessFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("UidsShortSessLoader", {
              attrs: {
                dependsOn:
                  _vm.onuUidsFact &&
                  _vm.onuAloneFact == "not-alone" &&
                  _vm.problemFact == "sometimes",
                uids: _vm.onuUidsFact,
                "active-if": _vm.isPon
              },
              model: {
                value: _vm.onuShortSessFact,
                callback: function($$v) {
                  _vm.onuShortSessFact = $$v
                },
                expression: "onuShortSessFact"
              }
            }),
            _vm._v(" "),
            _c("OnuShortSessInd", {
              attrs: { fact: _vm.onuShortSessFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("UidsLossLoader", {
              attrs: {
                dependsOn:
                  _vm.onuUidsFact &&
                  _vm.problemFact == "badly" &&
                  _vm.onuAloneFact == "not-alone",
                uids: _vm.onuUidsFact,
                "active-if": _vm.isPon
              },
              model: {
                value: _vm.onuLossFact,
                callback: function($$v) {
                  _vm.onuLossFact = $$v
                },
                expression: "onuLossFact"
              }
            }),
            _vm._v(" "),
            _c("OnuLossInd", {
              attrs: { fact: _vm.onuLossFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("UidsHungSessLoader", {
              attrs: {
                dependsOn:
                  _vm.onuUidsFact &&
                  _vm.onuAloneFact == "not-alone" &&
                  _vm.problemFact == "sometimes",
                uids: _vm.onuUidsFact,
                "active-if": _vm.isPon
              },
              model: {
                value: _vm.onuHungSessFact,
                callback: function($$v) {
                  _vm.onuHungSessFact = $$v
                },
                expression: "onuHungSessFact"
              }
            }),
            _vm._v(" "),
            _c("OnuHungSessInd", {
              attrs: { fact: _vm.onuHungSessFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("OnuTermSessLoader", {
              attrs: {
                lastSess: _vm.lastSessionFact,
                onu: _vm.onuStatusFact,
                service: _vm.serviceFact,
                onuMacs: _vm.onuMacsFact,
                dependsOn:
                  _vm.onuStatusFact &&
                  _vm.lastSessionFact &&
                  _vm.onuAloneFact == "not-alone",
                "active-if": _vm.isPon
              },
              model: {
                value: _vm.onuTermSessFact,
                callback: function($$v) {
                  _vm.onuTermSessFact = $$v
                },
                expression: "onuTermSessFact"
              }
            }),
            _vm._v(" "),
            _c("OnuTermSessInd", {
              ref: "onuTermSess",
              attrs: { fact: _vm.onuTermSessFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("ServiceVlanLoader", {
              attrs: {
                dependsOn: _vm.serviceFact,
                service: _vm.serviceFact,
                "active-if": _vm.isPon
              },
              model: {
                value: _vm.serviceVlanFact,
                callback: function($$v) {
                  _vm.serviceVlanFact = $$v
                },
                expression: "serviceVlanFact"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "panel panel-default" }, [
        _c("div", { staticClass: "panel-heading" }, [
          _vm._v("Коммутатор / Дерево")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "panel-body" },
          [
            _c("DeviceProblemInd", {
              ref: "deviceNews",
              attrs: { fact: _vm.newsFact }
            }),
            _vm._v(" "),
            _c("SwitchStatusLoader", {
              attrs: { dependsOn: _vm.serviceFact, "active-if": _vm.isSwitch },
              model: {
                value: _vm.switchStatusFact,
                callback: function($$v) {
                  _vm.switchStatusFact = $$v
                },
                expression: "switchStatusFact"
              }
            }),
            _vm._v(" "),
            _c("SwitchStatusInd", {
              attrs: { fact: _vm.switchStatusFact, visible: _vm.isSwitch }
            }),
            _vm._v(" "),
            _c("SwitchPortsLoader", {
              attrs: {
                dependsOn: _vm.switchStatusFact && _vm.serviceFact,
                "active-if": _vm.isSwitch,
                status: _vm.switchStatusFact,
                service: _vm.serviceFact
              },
              model: {
                value: _vm.switchPortsFact,
                callback: function($$v) {
                  _vm.switchPortsFact = $$v
                },
                expression: "switchPortsFact"
              }
            }),
            _vm._v(" "),
            _c("SwitchMacLoader", {
              attrs: {
                dependsOn: _vm.switchPortsFact,
                "active-if": _vm.isSwitch
              },
              model: {
                value: _vm.switchMacsFact,
                callback: function($$v) {
                  _vm.switchMacsFact = $$v
                },
                expression: "switchMacsFact"
              }
            }),
            _vm._v(" "),
            _c("SwitchUidLoader", {
              attrs: {
                dependsOn: _vm.switchMacsFact,
                "active-if": _vm.isSwitch
              },
              model: {
                value: _vm.switchUidsFact,
                callback: function($$v) {
                  _vm.switchUidsFact = $$v
                },
                expression: "switchUidsFact"
              }
            }),
            _vm._v(" "),
            _vm.isSwitch
              ? _c("SwitchPortOffLoader", {
                  attrs: {
                    dependsOn: _vm.serviceFact && _vm.switchStatusFact,
                    service: _vm.serviceFact,
                    switch: _vm.switchStatusFact
                  },
                  model: {
                    value: _vm.switchPortOffFact,
                    callback: function($$v) {
                      _vm.switchPortOffFact = $$v
                    },
                    expression: "switchPortOffFact"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("SwitchPortOffInd", {
              attrs: { fact: _vm.switchPortOffFact, visible: _vm.isSwitch }
            }),
            _vm._v(" "),
            _c("SwitchActiveSessLoader", {
              attrs: {
                dependsOn: _vm.switchUidsFact,
                "active-if": _vm.isSwitch
              },
              model: {
                value: _vm.switchActiveSessFact,
                callback: function($$v) {
                  _vm.switchActiveSessFact = $$v
                },
                expression: "switchActiveSessFact"
              }
            }),
            _vm._v(" "),
            _c("SwitchActiveSessInd", {
              attrs: { fact: _vm.switchActiveSessFact, visible: _vm.isSwitch }
            }),
            _vm._v(" "),
            _c("SwitchTermSessLoader", {
              attrs: {
                lastSess: _vm.lastSessionFact,
                switch: _vm.switchPortsFact,
                dependsOn: _vm.switchPortsFact && _vm.lastSessionFact,
                "active-if": _vm.isSwitch
              },
              model: {
                value: _vm.switchTermSessFact,
                callback: function($$v) {
                  _vm.switchTermSessFact = $$v
                },
                expression: "switchTermSessFact"
              }
            }),
            _vm._v(" "),
            _c("SwitchTermSessInd", {
              ref: "switchTermSess",
              attrs: { fact: _vm.switchTermSessFact, visible: _vm.isSwitch }
            }),
            _vm._v(" "),
            _c("SwitchShortSessLoader", {
              attrs: {
                dependsOn: _vm.switchUidsFact && _vm.problemFact == "sometimes",
                switch: _vm.switchUidsFact,
                "active-if": _vm.isSwitch
              },
              model: {
                value: _vm.switchShortSessFact,
                callback: function($$v) {
                  _vm.switchShortSessFact = $$v
                },
                expression: "switchShortSessFact"
              }
            }),
            _vm._v(" "),
            _c("SwitchShortSessInd", {
              attrs: { fact: _vm.switchShortSessFact, visible: _vm.isSwitch }
            }),
            _vm._v(" "),
            _c("SwitchHungSessLoader", {
              attrs: {
                dependsOn: _vm.switchUidsFact && _vm.problemFact == "sometimes",
                switch: _vm.switchUidsFact,
                "active-if": _vm.isSwitch
              },
              model: {
                value: _vm.switchHungSessFact,
                callback: function($$v) {
                  _vm.switchHungSessFact = $$v
                },
                expression: "switchHungSessFact"
              }
            }),
            _vm._v(" "),
            _c("SwitchHungSessInd", {
              attrs: { fact: _vm.switchHungSessFact, visible: _vm.isSwitch }
            }),
            _vm._v(" "),
            _c("SwitchLossLoader", {
              attrs: {
                dependsOn:
                  _vm.portLossFact != "no-loss" &&
                  _vm.switchUidsFact &&
                  _vm.problemFact == "badly",
                switch: _vm.switchUidsFact,
                "active-if": _vm.isSwitch
              },
              model: {
                value: _vm.switchLossFact,
                callback: function($$v) {
                  _vm.switchLossFact = $$v
                },
                expression: "switchLossFact"
              }
            }),
            _vm._v(" "),
            _c("SwitchLossInd", {
              attrs: { fact: _vm.switchLossFact, visible: _vm.isSwitch }
            }),
            _vm._v(" "),
            _c("TreeInfoLoader", {
              attrs: { dependsOn: _vm.serviceFact, "active-if": _vm.isPon },
              model: {
                value: _vm.treeInfoFact,
                callback: function($$v) {
                  _vm.treeInfoFact = $$v
                },
                expression: "treeInfoFact"
              }
            }),
            _vm._v(" "),
            _c("TreeStatusLoader", {
              attrs: { dependsOn: _vm.onuStatusFact, "active-if": _vm.isPon },
              model: {
                value: _vm.treeStatusFact,
                callback: function($$v) {
                  _vm.treeStatusFact = $$v
                },
                expression: "treeStatusFact"
              }
            }),
            _vm._v(" "),
            _c("TreeMacPresentLoader", {
              attrs: { dependsOn: _vm.treeStatusFact, visible: _vm.isPon },
              model: {
                value: _vm.treeMacPresentFact,
                callback: function($$v) {
                  _vm.treeMacPresentFact = $$v
                },
                expression: "treeMacPresentFact"
              }
            }),
            _vm._v(" "),
            _c("TreeMacPresentInd", {
              attrs: { fact: _vm.treeMacPresentFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("TreeMacLoader", {
              attrs: {
                dependsOn: _vm.treeStatusFact,
                "active-if": _vm.isPon,
                onu: _vm.onuStatusFact
              },
              model: {
                value: _vm.treeMacsFact,
                callback: function($$v) {
                  _vm.treeMacsFact = $$v
                },
                expression: "treeMacsFact"
              }
            }),
            _vm._v(" "),
            _c("TreeUidLoader", {
              attrs: { dependsOn: _vm.treeMacsFact, "active-if": _vm.isPon },
              model: {
                value: _vm.treeUidsFact,
                callback: function($$v) {
                  _vm.treeUidsFact = $$v
                },
                expression: "treeUidsFact"
              }
            }),
            _vm._v(" "),
            _c("TreeOnuOffLoader", {
              attrs: { dependsOn: _vm.treeStatusFact, "active-if": _vm.isPon },
              model: {
                value: _vm.treeOnuOffFact,
                callback: function($$v) {
                  _vm.treeOnuOffFact = $$v
                },
                expression: "treeOnuOffFact"
              }
            }),
            _vm._v(" "),
            _c("TreeOnuOffInd", {
              attrs: { fact: _vm.treeOnuOffFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("TreeShortSessLoader", {
              attrs: {
                dependsOn: _vm.treeUidsFact && _vm.problemFact == "sometimes",
                tree: _vm.treeUidsFact,
                "active-if": _vm.isPon
              },
              model: {
                value: _vm.treeShortSessFact,
                callback: function($$v) {
                  _vm.treeShortSessFact = $$v
                },
                expression: "treeShortSessFact"
              }
            }),
            _vm._v(" "),
            _c("TreeShortSessInd", {
              attrs: { fact: _vm.treeShortSessFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("TreeHungSessLoader", {
              attrs: {
                dependsOn: _vm.treeUidsFact && _vm.problemFact == "sometimes",
                tree: _vm.treeUidsFact,
                "active-if": _vm.isPon
              },
              model: {
                value: _vm.treeHungSessFact,
                callback: function($$v) {
                  _vm.treeHungSessFact = $$v
                },
                expression: "treeHungSessFact"
              }
            }),
            _vm._v(" "),
            _c("TreeHungSessInd", {
              attrs: { fact: _vm.treeHungSessFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("TreeLossLoader", {
              attrs: {
                dependsOn:
                  _vm.onuLossFact != "no-loss" &&
                  _vm.treeUidsFact &&
                  _vm.problemFact == "badly",
                tree: _vm.treeUidsFact,
                "active-if": _vm.isPon
              },
              model: {
                value: _vm.treeLossFact,
                callback: function($$v) {
                  _vm.treeLossFact = $$v
                },
                expression: "treeLossFact"
              }
            }),
            _vm._v(" "),
            _c("TreeLossInd", {
              attrs: { fact: _vm.treeLossFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("TreeActiveSessLoader", {
              attrs: { dependsOn: _vm.treeUidsFact, "active-if": _vm.isPon },
              model: {
                value: _vm.treeActiveSessFact,
                callback: function($$v) {
                  _vm.treeActiveSessFact = $$v
                },
                expression: "treeActiveSessFact"
              }
            }),
            _vm._v(" "),
            _c("TreeActiveSessInd", {
              attrs: { fact: _vm.treeActiveSessFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("TreeTermSessLoader", {
              attrs: {
                dependsOn:
                  _vm.treeStatusFact && _vm.lastSessionFact && _vm.serviceFact,
                "active-if": _vm.isPon,
                service: _vm.serviceFact,
                lastSess: _vm.lastSessionFact,
                onus: _vm.treeStatusFact
              },
              model: {
                value: _vm.treeTermSessFact,
                callback: function($$v) {
                  _vm.treeTermSessFact = $$v
                },
                expression: "treeTermSessFact"
              }
            }),
            _vm._v(" "),
            _c("TreeTermSessInd", {
              ref: "treeTermSess",
              attrs: { fact: _vm.treeTermSessFact, visible: _vm.isPon }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "panel panel-default" }, [
        _c("div", { staticClass: "panel-heading" }, [
          _vm._v("Коммутаторы выше / OLT")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "panel-body" },
          [
            _c("OltStatusLoader", {
              attrs: { dependsOn: _vm.onuStatusFact, "active-if": _vm.isPon },
              model: {
                value: _vm.oltStatusFact,
                callback: function($$v) {
                  _vm.oltStatusFact = $$v
                },
                expression: "oltStatusFact"
              }
            }),
            _vm._v(" "),
            _c("OltMacLoader", {
              attrs: { dependsOn: _vm.oltStatusFact, "active-if": _vm.isPon },
              model: {
                value: _vm.oltMacsFact,
                callback: function($$v) {
                  _vm.oltMacsFact = $$v
                },
                expression: "oltMacsFact"
              }
            }),
            _vm._v(" "),
            _c("OltUidLoader", {
              attrs: { dependsOn: _vm.oltMacsFact, "active-if": _vm.isPon },
              model: {
                value: _vm.oltUidsFact,
                callback: function($$v) {
                  _vm.oltUidsFact = $$v
                },
                expression: "oltUidsFact"
              }
            }),
            _vm._v(" "),
            _c("OltMacPresentLoader", {
              attrs: { dependsOn: _vm.oltMacsFact, "active-if": _vm.isPon },
              model: {
                value: _vm.oltMacPresentFact,
                callback: function($$v) {
                  _vm.oltMacPresentFact = $$v
                },
                expression: "oltMacPresentFact"
              }
            }),
            _vm._v(" "),
            _c("OltMacPresentInd", {
              attrs: { fact: _vm.oltMacPresentFact, "active-if": _vm.isPon }
            }),
            _vm._v(" "),
            _c("OltActiveSessLoader", {
              attrs: {
                dependsOn: _vm.oltUidsFact && _vm.problemFact == "sometimes",
                olt: _vm.oltUidsFact,
                "active-if": _vm.isPon
              },
              model: {
                value: _vm.oltActiveSessFact,
                callback: function($$v) {
                  _vm.oltActiveSessFact = $$v
                },
                expression: "oltActiveSessFact"
              }
            }),
            _vm._v(" "),
            _c("OltActiveSessInd", {
              attrs: { fact: _vm.oltActiveSessFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("OltShortSessLoader", {
              attrs: {
                dependsOn: _vm.oltUidsFact && _vm.problemFact == "sometimes",
                olt: _vm.oltUidsFact,
                "active-if": _vm.isPon
              },
              model: {
                value: _vm.oltShortSessFact,
                callback: function($$v) {
                  _vm.oltShortSessFact = $$v
                },
                expression: "oltShortSessFact"
              }
            }),
            _vm._v(" "),
            _c("OltShortSessInd", {
              attrs: { fact: _vm.oltShortSessFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("OltHungSessLoader", {
              attrs: {
                dependsOn: _vm.oltUidsFact && _vm.problemFact == "sometimes",
                olt: _vm.oltUidsFact,
                "active-if": _vm.isPon
              },
              model: {
                value: _vm.oltHungSessFact,
                callback: function($$v) {
                  _vm.oltHungSessFact = $$v
                },
                expression: "oltHungSessFact"
              }
            }),
            _vm._v(" "),
            _c("OltHungSessInd", {
              attrs: { fact: _vm.oltHungSessFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("OltLossLoader", {
              attrs: {
                dependsOn:
                  _vm.treeLossFact != "no-onus" &&
                  _vm.oltUidsFact &&
                  _vm.problemFact == "badly",
                olt: _vm.oltUidsFact,
                "active-if": _vm.isPon
              },
              model: {
                value: _vm.oltLossFact,
                callback: function($$v) {
                  _vm.oltLossFact = $$v
                },
                expression: "oltLossFact"
              }
            }),
            _vm._v(" "),
            _c("OltLossInd", {
              attrs: { fact: _vm.oltLossFact, visible: _vm.isPon }
            }),
            _vm._v(" "),
            _c("OltTermSessLoader", {
              attrs: {
                dependsOn:
                  _vm.oltStatusFact && _vm.lastSessionFact && _vm.serviceFact,
                "active-if": _vm.isPon,
                lastSess: _vm.lastSessionFact,
                olt: _vm.oltStatusFact,
                "service:serviceFact": ""
              },
              model: {
                value: _vm.oltTermSessFact,
                callback: function($$v) {
                  _vm.oltTermSessFact = $$v
                },
                expression: "oltTermSessFact"
              }
            }),
            _vm._v(" "),
            _c("OltTermSessInd", {
              ref: "oltTermSess",
              attrs: { fact: _vm.oltTermSessFact, visible: _vm.isPon }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button" },
          on: { click: _vm.createTask }
        },
        [_vm._v("Создать заявку")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }