<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Проверяет, есть ли живые МАКи на дереве

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

export default {
  name: "TreeMacPresentLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo()
    {
      const onus = this.dependsOn
      const count = onus.map(el => el.fdb.length).reduce((a,b) => a+b, 0)
      return count ? "macs-present" : "no-macs-present"
    },
  },
}

</script>