<template>
  <div v-if="fact && fact.length">
    {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"
import moment from "moment"

export default {
  
  name: "UserLastSessInd",
  mixins: [IndMixin],    
  
  computed: {
    status () {
       return 'Последняя сессия: завершена ' + this.fact[0].end + ' (' + this.fact[0].terminate_cause + ')'
    },

    shortStatus () {
      if (!this.fact || !this.fact.length) return 'Давно либо вовсе не был в сети'
      return this.fact[0].end + ' (' + this.fact[0].terminate_cause + ')'
    },

    lastTime () {
      if (!this.fact || !this.fact.length) return 'Давно либо вовсе не был в сети'
      return this.fact[0].end
    },

    lastCause () {
      if (!this.fact || !this.fact.length) return '-'
      return this.fact[0].terminate_cause
    }
  }
}
</script>
