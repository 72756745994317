<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

// Получает по списку МАКов на устройстве список всех уидов абонентов (с IP, если в данный момент есть сессия)

import darsan from "darsan"
import loaderMixin from "issue/page/loaders/LoaderMixin.vue"
import { mapTreeUids } from "issue/page/loaders/loaders.js"

export default {
  name: "TreeUidLoader",
  
  mixins: [loaderMixin],
  
  methods: {
    async loadInfo() {
      return mapTreeUids(this.dependsOn)
    },
  },
}

</script>
