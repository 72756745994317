<template>
  <div v-if=isFact>
    {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "SwitchPortOffInd",
  mixins: [IndMixin],    
  
  props: {
    service: Object,
  },
  
  computed: {
    status()
    {
      if (this.fact=='all-ports') return "Все остальные порты на этом устройстве включены"
      if (this.fact=='some-ports') return "Некоторые порты на устройстве отключены"
      if (this.fact=='no-ports') return "Все остальные порты на этом устройстве отключены"
      return `${this.$options.name}: ?`
    }
  },
}
</script>
