<template>
  <div class="text-danger" v-if="error">{{$options.name}}: {{error}}</div>
</template>

<script>

  import darsan from "darsan"
  import loaderMixin from "issue/page/loaders/LoaderMixin.vue"

  export default {
    name: "UserInfoLoader",

    mixins: [loaderMixin],

    methods: {
      async loadInfo () {
        return await darsan.get("", "client", `/client/${this.dependsOn}`)
      },
    },
  }

</script>