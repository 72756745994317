<template>
  <div v-if="fact">
    {{status}}
  </div>
</template>

<script>
// Отсутствие MAC-адресов на остальных деревьях головы

import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "OltMacPresentInd",
  mixins: [IndMixin],    
  
  computed: {
    status()
    {
      if (this.fact == "no-trees") return "На всех остальных деревьях отсутствуют МАКи"
      if (this.fact == "all-trees") return "На всех остальных деревьях есть МАКи"
      if (this.fact == "some-trees") "На некоторых остальных деревьях отсутствуют МАКи" 
      return `${this.$options.name}: ?`
    },
  },
}
</script>
