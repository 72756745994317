<template></template>

<script>
import {loadingValue} from "common.js"
export default {

  name: "IndMixin",
  
  props: {
    name: String,
    fact: [String, Object, Array, Number, Boolean],
  },
  
  methods: {
    prepare(fact)
    {
      return fact
    }
  },
  
  computed: {
    isLoading()
    {
      return this.fact===undefined
    },
    isFact()
    {
      return this.fact!==null
    }
  },
        
  watch: {
    fact: {
      immediate: true, 
      handler(val)
      {
        if (!val)
        {
          return this.$emit("input", null)
        }
        
        this.$emit("input", this.prepare(val))
      }
    },
  }

}
</script>
