<template>
  <div v-if="fact">
  {{status}}
  </div>
</template>

<script>
import IndMixin from "issue/page/indicators/IndMixin.vue"

export default {
  
  name: "OnuHungSessionInd",
  mixins: [IndMixin],    
  
  computed: {
    status()
    {
      if (this.fact=='all-hung-sessions') return "Все остальные сессии на ONU - зависшие"
      if (this.fact=='some-hung-sessions') return "Некоторые сессии на ONU зависшие"
      if (this.fact=='no-hung-sessions') return "Нет зависших сессий на ONU"
    }
  },
}
</script>
